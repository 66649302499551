/* eslint-disable max-classes-per-file */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable class-methods-use-this */
/* eslint-disable new-cap */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable import/order */
import React, { useCallback, useRef, useState } from 'react';
import VideoInterval from './VideoInterval';
import { secondsToTime, detectAgent } from '../../utils';
import shaka from 'shaka-player/dist/shaka-player.ui';
import ResumePopup from '../ResumePopup';
import 'shaka-player/dist/controls.css';
import { useSelector } from 'react-redux';
import analytic, {
  analyticEvents,
  analyticTypes
} from '../../service/analytic';
import CutomSubTitlePopup from './CustomSubTitlePopup';
import { fontSizes } from './CustomSubTitlePopup/constants';
import { parsingResponse } from './palycon-helper';
/**
 * A React component for shaka-player.
 * @param {string} src
 * @param {shaka.extern.PlayerConfiguration} config
 * @param {boolean} autoPlay
 * @param {number} width
 * @param {number} height
 * @param ref
 * @returns {*}
 * @constructor
 */
function ShakaPlayer(
  {
    videoInfo,
    src,
    streamType,
    drmToken,
    config,
    autoPlay,
    width,
    height,
    handlePlayNext,
    handleUpdateHistory,
    setShouldGetHistory,
    historyData,
    autoplay = false,
    handleEndHistory
  },
  ref
) {
  const [showResumePopup, setShowResumePopup] = useState(false);
  const [watchedLength, setWatchedLength] = useState();
  const [runInterval, setRunInterval] = useState(false);
  const [subtitleFormatPopup, setSubtitleFormatPopup] = useState(false);
  const uiContainerRef = useRef(null);
  const videoRef = useRef(null);
  const controller = useRef({});
  const pausing = useRef();
  const user = useSelector((state) => state.auth.user);
  const captionSettings = useSelector(
    (state) => state.appSettings.captionSettings
  );

  const updateHistoryCallback = useCallback(() => {
    const { videoElement } = controller.current;
    const currentTime = videoElement.currentTime;
    const duration = videoElement.duration;

    handleUpdateHistory(currentTime, duration);

    if (videoInfo) {
      const paramsEvent = {
        videoID: videoInfo.id,
        videoTitle: videoInfo.titleLocalized,
        videoDuration: videoInfo.duration,
        videoRating: videoInfo.rating,
        contentType: videoInfo.type,
        contentRights: videoInfo.contentRights,
        length: duration,
        position: currentTime
      };
      if (videoInfo.tags && videoInfo.tags.genres) {
        paramsEvent.videoGenre = videoInfo.tags.genres;
      }
      if (videoInfo.season) {
        paramsEvent.seasonID = videoInfo.season.id;
        paramsEvent.seasonNumber = videoInfo.season.titleLocalized;
        paramsEvent.seriesID = videoInfo.seriesID;
        paramsEvent.seriesName = videoInfo.series.titleLocalized;
      }

      const timeRange = 30;
      if (
        currentTime >= 0.25 * duration - timeRange &&
        currentTime <= 0.25 * duration + timeRange
      ) {
        analytic(
          analyticTypes.event,
          analyticEvents.MEDIA_PLAYBACK.MEDIA_REACH_25,
          {
            params: paramsEvent,
            user
          }
        );
      } else if (
        currentTime >= 0.5 * duration - timeRange &&
        currentTime <= 0.5 * duration + timeRange
      ) {
        analytic(
          analyticTypes.event,
          analyticEvents.MEDIA_PLAYBACK.MEDIA_REACH_50,
          {
            params: paramsEvent,
            user
          }
        );
      } else if (
        currentTime >= 0.75 * duration - timeRange &&
        currentTime <= 0.75 * duration + timeRange
      ) {
        analytic(
          analyticTypes.event,
          analyticEvents.MEDIA_PLAYBACK.MEDIA_REACH_75,
          {
            params: paramsEvent,
            user
          }
        );
      }
    }
  }, [handleUpdateHistory, videoInfo, user]);

  const handleResume = useCallback(() => {
    const { videoElement } = controller.current;
    setShowResumePopup(false);
    videoElement.currentTime = watchedLength;
    videoElement.play();
  }, [watchedLength]);

  const handleStartOver = useCallback(() => {
    const { videoElement } = controller.current;
    setShowResumePopup(false);
    videoElement.currentTime = 0;
    videoElement.play();
  }, []);

  const handleMediaStart = useCallback(
    (e) => {
      // console.log('video started', e)
      setRunInterval(true);

      if (pausing.current) {
        // console.log('resume')
        pausing.current = false;

        if (videoInfo) {
          const paramsEvent = {
            contentType: videoInfo.type,
            videoID: videoInfo.id,
            videoTitle: videoInfo.titleLocalized,
            videoDuration: videoInfo.duration
          };
          if (videoInfo.tags && videoInfo.tags.genres) {
            paramsEvent.videoGenre = videoInfo.tags.genres;
          }
          if (videoInfo.season) {
            paramsEvent.seasonID = videoInfo.season.id;
            paramsEvent.seasonNumber = videoInfo.season.titleLocalized;
            paramsEvent.seriesID = videoInfo.seriesID;
            paramsEvent.seriesName = videoInfo.series.titleLocalized;
          }

          analytic(
            analyticTypes.event,
            analyticEvents.MEDIA_PLAYBACK.MEDIA_RESUME,
            {
              params: paramsEvent,
              user
            }
          );
        }
      }
    },
    [videoInfo, user]
  );

  const handleMediaPause = useCallback(() => {
    if (window.autoplayAfterMuted) {
      const { videoElement } = controller.current;
      videoElement.play();
      window.autoplayAfterMuted = false;
      return;
    }

    pausing.current = true;
    setRunInterval(false);

    if (videoInfo) {
      const paramsEvent = {
        contentType: videoInfo.type,
        videoID: videoInfo.id,
        videoTitle: videoInfo.titleLocalized,
        videoDuration: videoInfo.duration
      };
      if (videoInfo.tags && videoInfo.tags.genres) {
        paramsEvent.videoGenre = videoInfo.tags.genres;
      }
      if (videoInfo.season) {
        paramsEvent.seasonID = videoInfo.season.id;
        paramsEvent.seasonNumber = videoInfo.season.titleLocalized;
        paramsEvent.seriesID = videoInfo.seriesID;
        paramsEvent.seriesName = videoInfo.series.titleLocalized;
      }

      analytic(analyticTypes.event, analyticEvents.MEDIA_PLAYBACK.MEDIA_PAUSE, {
        params: paramsEvent,
        user
      });
    }
  }, [videoInfo, user]);

  const handleDurationChange = useCallback(() => {
    const { videoElement } = controller.current;
    if (videoInfo) {
      if (historyData && historyData.mediaId === videoInfo.id) {
        const mediaDuration = videoElement.duration;
        const historyPercentage = historyData.position.percentage;
        const historyPosition = (mediaDuration * historyPercentage) / 100;

        if (!isNaN(historyPosition)) {
          if (videoElement.currentTime > historyPosition) return;

          // force start over
          if (historyPercentage <= 5 || historyPercentage >= 95) {
            return setWatchedLength(0);
          }

          setWatchedLength(historyPosition);
          videoElement.pause();
          return setShowResumePopup(true);
        }
      } else {
        videoElement.play();
      }
    }
  }, [historyData, videoInfo]);

  const handleMediaEnd = useCallback(
    async (e) => {
      const { videoElement } = controller.current;
      const durationInSecond = videoInfo.duration * 60;

      if (videoInfo) {
        const paramsEvent = {
          contentType: videoInfo.type,
          videoID: videoInfo.id,
          videoTitle: videoInfo.titleLocalized,
          videoDuration: videoInfo.duration
        };
        if (videoInfo.tags && videoInfo.tags.genres) {
          paramsEvent.videoGenre = videoInfo.tags.genres;
        }
        if (videoInfo.season) {
          paramsEvent.seasonID = videoInfo.season.id;
          paramsEvent.seasonNumber = videoInfo.season.titleLocalized;
          paramsEvent.seriesID = videoInfo.seriesID;
          paramsEvent.seriesName = videoInfo.series.titleLocalized;
        }

        analytic(analyticTypes.event, analyticEvents.MEDIA_PLAYBACK.MEDIA_END, {
          params: paramsEvent,
          user
        });
      }

      // send last history when duration greater than position
      handleEndHistory &&
        handleEndHistory(
          durationInSecond,
          videoElement.currentTime,
          videoElement.duration
        );

      if (!handlePlayNext) {
        setRunInterval(false);
      }

      if (handlePlayNext) {
        const canPlayNext = await handlePlayNext();
        if (!canPlayNext) {
          setRunInterval(false);
        }
      }
    },
    [handlePlayNext, videoInfo, handleEndHistory, user]
  );

  const handleMediaSeek = useCallback(() => {
    const { videoElement } = controller.current;

    if (videoInfo) {
      const paramsEvent = {
        contentType: videoInfo.type,
        videoID: videoInfo.id,
        elapsedTime: videoElement.currentTime,
        videoTitle: videoInfo.titleLocalized,
        videoDuration: videoInfo.duration
      };
      if (videoInfo.tags && videoInfo.tags.genres) {
        paramsEvent.videoGenre = videoInfo.tags.genres;
      }
      if (videoInfo.season) {
        paramsEvent.seasonID = videoInfo.season.id;
        paramsEvent.seasonNumber = videoInfo.season.titleLocalized;
        paramsEvent.seriesID = videoInfo.seriesID;
        paramsEvent.seriesName = videoInfo.series.titleLocalized;
      }

      analytic(analyticTypes.event, analyticEvents.MEDIA_PLAYBACK.MEDIA_SEEK, {
        params: paramsEvent,
        user
      });
    }
  }, [videoInfo, user]);

  const handlerError = useCallback((err) => {
    // console.log(err)
  }, []);

  const handerLoaded = useCallback((event) => {
    throw new Error(event);
  }, []);

  const handleSubtitleFormatPopup = useCallback(
    (event) => {
      setSubtitleFormatPopup(!subtitleFormatPopup);
    },
    [subtitleFormatPopup]
  );
  const captionFormat = useCallback(() => {
    shaka.ui.captionFormat = class extends shaka.ui.Element {
      constructor(parent, controls) {
        super(parent, controls);
        this.isShowPopup = false;
        this.button_ = document.createElement('button');
        this.button_.classList.add('material-icons');
        this.button_.classList.add('caption-mobile');
        this.button_.textContent = 'Subtitle Format';
        this.parent.appendChild(this.button_);
        this.eventManager.listen(this.button_, 'click', () => {
          const event = new Event('subtitleFormatPopup');
          window.dispatchEvent(event);
        });
      }
    };
    shaka.ui.captionFormat.Factory = class {
      create(rootElement, controls) {
        return new shaka.ui.captionFormat(rootElement, controls);
      }
    };
    shaka.ui.Controls.registerElement(
      'caption_format',
      new shaka.ui.captionFormat.Factory()
    );
  }, []);

  // EVENT LISTENERS
  const addEventListener = useCallback(async () => {
    const { videoElement } = controller.current;
    videoElement.addEventListener('play', handleMediaStart);
    videoElement.addEventListener('pause', handleMediaPause);
    videoElement.addEventListener('durationchange', handleDurationChange);
    videoElement.addEventListener('seeked', handleMediaSeek);
    videoElement.addEventListener('ended', handleMediaEnd);
  }, [
    handleMediaStart,
    handleMediaPause,
    handleDurationChange,
    handleMediaSeek,
    handleMediaEnd
  ]);

  const removeEventListener = useCallback(() => {
    const { videoElement } = controller.current;
    if (videoElement) {
      videoElement.removeEventListener('play', handleMediaStart);
      videoElement.removeEventListener('pause', handleMediaPause);
      videoElement.removeEventListener('durationchange', handleDurationChange);
      videoElement.removeEventListener('seeked', handleMediaSeek);
      videoElement.removeEventListener('ended', handleMediaEnd);
    }
  }, [
    handleMediaStart,
    handleMediaPause,
    handleDurationChange,
    handleMediaSeek,
    handleMediaEnd
  ]);

  // Effect to handle component mount & mount.
  // Not related to the src prop, this hook creates a shaka.Player instance.
  // This should always be the first effect to run.
  React.useEffect(() => {
    shaka.polyfill.installAll();
    if (!shaka.Player.isBrowserSupported()) {
      return false;
    }

    // Register custom caption format button
    // This should be always added before player initialization
    captionFormat();
    const player = new shaka.Player();
    const ui = new shaka.ui.Overlay(
      player,
      uiContainerRef.current,
      videoRef.current
    );
    const controls = ui.getControls();

    // Store Shaka's API in order to expose it as a handle.
    controller.current = {
      player,
      ui,
      videoElement: videoRef.current,
      controls
    };
    window.player = player;
    player.addEventListener('error', handlerError);
    player.addEventListener('loaded', handerLoaded);
    player.setTextTrackVisibility(true);
  }, [handlerError, handerLoaded, captionFormat]);

  // Load the source url when we have one.
  React.useEffect(() => {
    const { player, videoElement } = controller.current;
    player.attach(videoRef.current);
    if (player) {
      addEventListener();
      if (streamType === 'hls') {
        player
          .getNetworkingEngine()
          .registerRequestFilter(function (type, request) {
            if (type === shaka.net.NetworkingEngine.RequestType.LICENSE) {
              if (drmToken) {
                const originalPayload = new Uint8Array(request.body);
                const base64Payload =
                  shaka.util.Uint8ArrayUtils.toBase64(originalPayload);
                const params = `spc=${encodeURIComponent(base64Payload)}`;

                request.body = shaka.util.StringUtils.toUTF8(params);
                request.headers['Content-Type'] =
                  'application/x-www-form-urlencoded';
                request.headers['pallycon-customdata-v2'] = drmToken;
              }
            }
          });

        player
          .getNetworkingEngine()
          .registerResponseFilter(function (type, response) {
            if (drmToken) {
              // Alias some utilities provided by the library.
              if (type === shaka.net.NetworkingEngine.RequestType.LICENSE) {
                const responseText = shaka.util.StringUtils.fromUTF8(
                  response.data
                ).trim();
                response.data =
                  shaka.util.Uint8ArrayUtils.fromBase64(responseText).buffer;
                parsingResponse(response);
              }
            }
          });
      } else {
        player
          .getNetworkingEngine()
          .registerRequestFilter(function (type, request) {
            if (type === shaka.net.NetworkingEngine.RequestType.LICENSE) {
              if (drmToken) {
                request.headers['pallycon-customdata-v2'] = drmToken;
              }
            }
          });

        player
          .getNetworkingEngine()
          .registerResponseFilter(function (type, response) {
            if (drmToken) {
              // Alias some utilities provided by the library.
              if (type === shaka.net.NetworkingEngine.RequestType.LICENSE) {
                parsingResponse(response);
              }
            }
          });
      }
    }
    return () => {
      if (videoInfo) {
        const paramsEvent = {
          contentType: videoInfo.type,
          videoID: videoInfo.id,
          videoTitle: videoInfo.titleLocalized,
          videoDuration: videoInfo.duration,
          elapsedTime: videoElement.currentTime
        };
        if (videoInfo.tags && videoInfo.tags.genres) {
          paramsEvent.videoGenre = videoInfo.tags.genres;
        }
        if (videoInfo.season) {
          paramsEvent.seasonID = videoInfo.season.id;
          paramsEvent.seasonNumber = videoInfo.season.titleLocalized;
          paramsEvent.seriesID = videoInfo.seriesID;
          paramsEvent.seriesName = videoInfo.series.titleLocalized;
        }

        analytic(
          analyticTypes.event,
          analyticEvents.MEDIA_PLAYBACK.MEDIA_EXIT,
          {
            params: paramsEvent,
            user
          }
        );
      }

      player.detach();
      removeEventListener();
    };
  }, [
    src,
    addEventListener,
    removeEventListener,
    user,
    videoInfo,
    drmToken,
    streamType
  ]);

  React.useEffect(() => {
    window.addEventListener('subtitleFormatPopup', handleSubtitleFormatPopup);
    return () => {
      window.removeEventListener(
        'subtitleFormatPopup',
        handleSubtitleFormatPopup
      );
    };
  }, [handleSubtitleFormatPopup]);

  React.useEffect(() => {
    const root = document.querySelector(':root');
    if (detectAgent('mobile')) {
      setSubtitleFormatPopup(false);
      root.style.setProperty('--captionSize', fontSizes['50%'].fontSize);
      root.style.setProperty(
        '--captionLineHeight',
        fontSizes['50%'].lineHeight
      );
      return;
    }

    root.style.setProperty(
      '--captionSize',
      fontSizes[captionSettings.fontSize].fontSize
    );
    root.style.setProperty(
      '--captionLineHeight',
      fontSizes[captionSettings.fontSize].lineHeight
    );
    root.style.setProperty(
      '--captionColor',
      captionSettings.fontColor.toLowerCase() === 'yellow'
        ? '#FFC700'
        : '#FFFFFF'
    );
    root.style.setProperty(
      '--captionEdgesTextShadow',
      captionSettings.fontEdges.toLowerCase() === 'shadow'
        ? '0px 2px 2px rgba(0, 0, 0, 0.64)'
        : '0px 2px 2px rgba(0, 0, 0, 0.64), 0px 2px 2px rgba(0, 0, 0, 0.64),0px 2px 2px rgba(0, 0, 0, 0.64),0px 2px 2px rgba(0, 0, 0, 0.64)'
    );
    root.style.setProperty(
      '--captionEdgesTextBorder',
      captionSettings.fontEdges.toLowerCase() === 'border' ? '2px black' : ''
    );
    root.style.setProperty(
      '--captionBgTransparant',
      captionSettings.bgTransparancy
    );
  }, [captionSettings]);

  // Define a handle for easily referencing Shaka's player & ui API's.
  React.useImperativeHandle(ref, () => ({
    get player() {
      return controller.current.player;
    },
    get ui() {
      return controller.current.ui;
    },
    get videoElement() {
      return controller.current.videoElement;
    },
    get controls() {
      return controller.current.controls;
    }
  }));

  return (
    <div ref={uiContainerRef} style={{ position: 'relative' }}>
      {runInterval && (
        <VideoInterval updateHistoryCallback={updateHistoryCallback} />
      )}
      <video
        ref={videoRef}
        autoPlay
        width={width}
        height={height}
        style={{ maxWidth: '100%', width, height }}
      />
      {showResumePopup && (
        <ResumePopup
          watchedLength={secondsToTime(watchedLength)}
          onResume={handleResume}
          onStartOver={handleStartOver}
        />
      )}
      {subtitleFormatPopup && <CutomSubTitlePopup />}
    </div>
  );
}

export default React.memo(React.forwardRef(ShakaPlayer));
