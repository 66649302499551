import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getMessaging } from 'firebase/messaging';
import { getRemoteConfig } from 'firebase/remote-config';

import { isFirebaseMessagingSupported } from '../utils';
import { BASE_URL } from '../config/URL';
import firebaseConfig from './firebaseConfig';
import gcpPaths from '../config/gcp-paths';

const app = initializeApp(firebaseConfig);
export const firebaseAnalytic = getAnalytics(app);
export const messaging = isFirebaseMessagingSupported() ? getMessaging() : {};
export const remoteConfig = getRemoteConfig(app);

const nodeEnv = process.env.REACT_APP_NODE_ENV;
export const defaultConfigBaseUrl = BASE_URL;

const firebaseConfigKeys = () => {
  const envSuffix = {
    production: '',
    staging: 'Uat',
    development: 'Dev'
  };

  const maintenanceSuffix = {
    production: 'Sushiroll',
    staging: 'UatSushiroll',
    development: 'DevSushiroll'
  };
  const envSuffixVal = envSuffix[nodeEnv] || '';

  // isMaintenance key
  const isMaintenance = `isMaintenance${maintenanceSuffix}`;

  // generate base url key for each path based on gcp-paths configuration
  const baseURLSuffix = `ApiBaseUrl${envSuffixVal}`;
  const baseURLKeys = gcpPaths.map((item) => item + baseURLSuffix);
  const baseURL = baseURLKeys.reduce(
    (accumulator, currentValue) => ({
      ...accumulator,
      [currentValue.replace(baseURLSuffix, '')]: currentValue
    }),
    {}
  );
  const defaultURL = baseURLKeys.reduce(
    (accumulator, currentValue) => ({
      ...accumulator,
      [currentValue]: defaultConfigBaseUrl
    }),
    {}
  );

  return {
    isMaintenance,
    baseURL,
    defaultURL
  };
};
export const configKeys = firebaseConfigKeys();

remoteConfig.settings.minimumFetchIntervalMillis =
  nodeEnv === 'production' ? 3600000 : 900000;
remoteConfig.defaultConfig = {
  isMaintenanceSushiroll: false,
  isMaintenanceUatSushiroll: false,
  isMaintenanceDevSushiroll: false,
  userApiBaseUrl: defaultConfigBaseUrl,
  userApiBaseUrlUat: defaultConfigBaseUrl,
  userApiBaseUrlDev: defaultConfigBaseUrl,
  ...configKeys.defaultURL
};
