/* eslint-disable no-unused-vars */
import axios from 'axios';
import { getValue } from 'firebase/remote-config';
import { JWT_APP_ID } from '../../config/URL';
import {
  generateDeviceInfo,
  generateErrorDetail,
  retryOperation,
  deleteCookie
} from '../../utils';
import { errorCode } from '../../constants';
import { clevertapUserLogin } from '../../service/clevertap';
import analytic, {
  analyticEvents,
  analyticTypes
} from '../../service/analytic';
import { configKeys, remoteConfig } from '../../service/firebase';
import { initFbPixelUserData } from '../../service/facebookPixel';
import { showToastNotif } from './notification';

const BASE_CONFIG_URL = getValue(
  remoteConfig,
  configKeys.baseURL.user
).asString();

const accountUrl = `${BASE_CONFIG_URL}/user/v1.0/accounts`;
const accountUrlVOneOne = `${BASE_CONFIG_URL}/user/v1.1/accounts`;
const sessionURLv12 = `${BASE_CONFIG_URL}/user/v1.2/sessions`;
const loginUrl = `${BASE_CONFIG_URL}/user/v1.0/sessions`;
// const loginUrlVOneOne = `${BASE_CONFIG_URL}/user/v1.1/sessions`
const profileUrl = `${BASE_CONFIG_URL}/user/v1.1/profile`;
const otpUrl = `${BASE_CONFIG_URL}/user/v1.0/sessions/otp`;
const refreshUrl = `${BASE_CONFIG_URL}/user/v1.2/sessions/refresh`;
const pruningUrl = `${BASE_CONFIG_URL}/user/v1.2/sessions/pruning`;
const forgotPassUrl = `${BASE_CONFIG_URL}/user/v1.0/passwords/forget`;
const resetPassUrl = `${BASE_CONFIG_URL}/user/v1.0/passwords/reset`;
const verifyUrl = `${BASE_CONFIG_URL}/user/v1.0/accounts/verify`;
const campaignUrl = `${BASE_CONFIG_URL}/user/v1.1/accounts/referral`;
const whatsappLinkUrl = `${BASE_CONFIG_URL}/screen/v1.0/setting/csWhatsapp`;
const updatePasswordUrl = `${BASE_CONFIG_URL}/user/v1.0/passwords`;
const oauthURL = `${BASE_CONFIG_URL}/user/v1.2/sessions/oauth`;
const emailTester =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const consentUrl = `${BASE_CONFIG_URL}/user/v1.0/consents`;

export const authPopup = {
  show: (configs) => (dispatch, getState) => {
    dispatch({
      type: 'SHOW_AUTH_POPUP',
      configs
    });
  },
  close: () => (dispatch, getState) => {
    dispatch({
      type: 'CLOSE_AUTH_POPUP'
    });
  }
};

export function getAuthInfo() {
  return (dispatch, getState) => {
    const token = localStorage.getItem('gfToken');
    let user = null;
    let refreshToken = null;

    if (token) {
      try {
        user = JSON.parse(localStorage.getItem('gfUser'));
        refreshToken = localStorage.getItem('gfRefreshToken');
      } catch (err) {
        return err;
      }
    }

    if (token && refreshToken && user) {
      dispatch(setToken(token, refreshToken));
      // dispatch(setUser(user))
      dispatch(getAccountInfo({ refreshAccountInfo: true }));
    }
  };
}

export function getAccountInfo({ refreshAccountInfo = false } = {}) {
  return async (dispatch, getState) => {
    const state = getState();
    const { token, user } = state.auth;

    dispatch({
      type: 'GET_ACCOUNT_INFO'
    });

    try {
      const subs = user?.account?.subscriptions;
      const priceData = subs.find((el) => el.packagePrice);
      if (
        refreshAccountInfo ||
        !user ||
        (!priceData && subs && !subs.length < 1)
      ) {
        const res = await axios.get(accountUrlVOneOne, {
          headers: {
            Authorization: token
          }
        });

        await initFbPixelUserData(res.data.data);
        dispatch({
          type: 'GET_ACCOUNT_INFO_SUCCESS',
          data: res.data.data
        });
      } else {
        dispatch({
          type: 'GET_ACCOUNT_INFO_SUCCESS_FROM_STATE'
        });
      }

      // Store Showtime click_id to database
      const clickId = localStorage.getItem('clickId');
      if (clickId) {
        await dispatch(sendRefferalKey(clickId));
      }

      // const userData = generateUserData(res?.data?.data, {
      //   separateSensitiveData: true,
      // })
      // if (window?.fbq) {
      //   window.fbq(
      //     'init',
      //     process.env.REACT_APP_FACEBOOK_PIXEL_ID,
      //     userData?.sensitiveData
      //   )
      // }
    } catch (err) {
      if (err.response && err.response.data) {
        dispatch({
          type: 'GET_ACCOUNT_INFO_FAILURE',
          err: err.response.data.error.message
        });
      }
    }
  };
}

// function setUser(user) {
//   return (dispatch) => {
//     dispatch({
//       type: 'SET_USER',
//       user,
//     })
//   }
// }

export function setToken(token, refreshToken) {
  return (dispatch) => {
    dispatch({
      type: 'SET_TOKEN',
      token,
      refreshToken
    });
  };
}

export function registerByEmail(name, email, password) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: 'REGISTER_USER'
      });
      const res = await axios.post(
        accountUrl,
        {
          email: emailTester.test(email) ? email.toLowerCase() : email,
          password,
          name
        },
        {
          headers: {
            'Cloudfront-JWT-AppId': JWT_APP_ID
          }
        }
      );

      const userID = res.data.data.userId;
      const paramsEvent = {
        userID,
        profileName: name,
        accountName: name,
        accountEmail: email
      };
      analytic(
        analyticTypes.event,
        analyticEvents.AUTH.COMPLETE_REGISTER_BY_EMAIL,
        {
          params: paramsEvent
        }
      );
      dispatch({
        type: 'REGISTER_USER_SUCCESS'
      });
      dispatch(loginByEmail(email, password));
    } catch (err) {
      let errorMessage = 'General error, please try again later';
      if (err.response && err.response.data) {
        const { error } = err.response.data;
        errorMessage = error.details[0] || error.message;
        if (error.code === errorCode.INVALID_PARAMETERS) {
          errorMessage = 'Password should be at least 8 characters';
        }
        if (error.code === errorCode.EMAIL_ALREADY_EXIST) {
          errorMessage = 'Email has existed, please use another email';
        }
      }

      dispatch({
        type: 'REGISTER_USER_FAILURE',
        err: errorMessage
      });

      throw err;
    }
  };
}

export function logout() {
  return async (dispatch, getState) => {
    const state = getState();
    const { token } = state.auth;
    const { user } = state.auth;

    const cleanUserStorage = async () => {
      await localStorage.removeItem('token');
      await localStorage.removeItem('refreshToken');
      await deleteCookie('token');
      await deleteCookie('refreshToken');
      await deleteCookie('user');
      await localStorage.removeItem('sessionStatus');
      await localStorage.removeItem('partner_id');
    };

    if (!token) {
      await cleanUserStorage();
      return null;
    }

    const paramsEvent = {
      time: new Date().getTime()
    };
    analytic(analyticTypes.event, analyticEvents.AUTH.LOGOUT, {
      params: paramsEvent,
      user
    });
    dispatch({ type: 'LOGOUT' });

    try {
      axios.delete(loginUrl, {
        headers: {
          Authorization: token
        }
      });
      // if (!token) return null
    } catch (err) {
      const errorData = generateErrorDetail(err);
      dispatch(showToastNotif('error', errorData.message));
      dispatch({ type: 'LOGOUT_FAILED', err: errorData });
    } finally {
      await cleanUserStorage();
      dispatch({ type: 'LOGOUT_SUCCESS' });
      dispatch({ type: 'RESET_AUTH_STATE' });
      dispatch({ type: 'RESET_CONTINUE_WATCHING_STATE' });
      dispatch({ type: 'RESET_SUBSCRIPTION_TRANSACTION_STATE' });
    }
  };
}

export const logoutAllDevice = () => async (dispatch, getState) => {
  const logoutToken = localStorage.getItem('logoutToken');

  dispatch({ type: 'LOGOUT' });

  axios.delete(`${sessionURLv12}/clearByAccountId`, {
    headers: {
      Authorization: logoutToken
    }
  });

  return window.location.reload();
};

export function loginByEmail(email, password) {
  return async (dispatch, getState) => {
    const state = getState();
    const { consentSelection } = state.auth;
    try {
      dispatch({
        type: 'LOGIN'
      });

      const res = await axios.post(
        sessionURLv12,
        {
          type: 'username',
          username: emailTester.test(email) ? email.toLowerCase() : email,
          password,
          device: generateDeviceInfo({ fcmCheck: true })
        },
        {
          headers: {
            'Cloudfront-JWT-AppId': JWT_APP_ID
          }
        }
      );

      const paramsEvent = {
        time: new Date().getTime(),
        userID: res.data.data.account.userId
      };
      const user = {
        ...res.data.data,
        telco: null
      };
      analytic(
        analyticTypes.event,
        analyticEvents.AUTH.COMPLETE_LOGIN_BY_EMAIL,
        {
          params: paramsEvent,
          user
        }
      );
      clevertapUserLogin(res.data.data);
      await initFbPixelUserData(user);
      await dispatch(validateSession(user?.isNewDevice, user?.accessToken));
      dispatch({
        type: 'LOGIN_SUCCESS',
        data: res.data.data
      });
      if (consentSelection === null) {
        await dispatch(getConsent()); // dont call this if consentSelection is not null
      } else {
        await dispatch(postConsent());
      }
      // dispatch(getAccountInfo())
    } catch (err) {
      const code = err.response ? err.response.data.error.code : err;
      let mes = '';

      if (
        code === errorCode.INVALID_CREDENTIAL ||
        code === errorCode.INVALID_PARAMETERS
      ) {
        mes = 'Invalid Credentials';
      } else {
        mes = err.response
          ? err.response.data.error.message
          : 'General Login Error';
      }

      dispatch({
        type: 'LOGIN_FAILURE',
        err: mes
      });
      throw err;
    }
  };
}

export const validateSession =
  (isNewDevice, accessToken) => async (dispatch, getState) => {
    dispatch({
      type: 'VALIDATE_SESSION'
    });

    try {
      const validate = new Promise((resolve, reject) => {
        let counter = 0;
        const validatingLoginSession = async () => {
          counter += 1;
          try {
            await axios.post(
              `${sessionURLv12}/validateSession`,
              {
                isNewDevice
              },
              {
                headers: {
                  'Cloudfront-JWT-AppId': JWT_APP_ID,
                  Authorization: accessToken
                }
              }
            );

            return resolve('success');
          } catch (err) {
            // eslint-disable-next-line prefer-promise-reject-errors
            if (counter >= 3) return reject('failed');

            setTimeout(validatingLoginSession, 10000);
          }
        };

        validatingLoginSession();
      });
      await validate;

      dispatch({
        type: 'VALIDATE_SESSION_SUCCESS'
      });

      return 'success';
    } catch (err) {
      dispatch({
        type: 'VALIDATE_SESSION_FAILED',
        data: accessToken
      });
      throw err;
    }
  };

export function forgotPassword(email) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: 'FORGOT_PASSWORD'
      });
      await axios.post(
        forgotPassUrl,
        {
          email
        },
        {
          headers: {
            'Cloudfront-JWT-AppId': JWT_APP_ID
          }
        }
      );

      dispatch({
        type: 'FORGOT_PASSWORD_SUCCESS'
      });
    } catch (err) {
      dispatch({
        type: 'FORGOT_PASSWORD_FAILURE',
        err: err.response.data.error.message
      });
    }
  };
}

export function resetPassword(password, token) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: 'RESET_PASSWORD'
      });
      await axios.post(
        resetPassUrl,
        {
          password,
          token
        },
        {
          headers: {
            'Cloudfront-JWT-AppId': JWT_APP_ID
          }
        }
      );

      dispatch({
        type: 'RESET_PASSWORD_SUCCESS'
      });
    } catch (err) {
      const errorData = generateErrorDetail(err);
      dispatch(showToastNotif('error', errorData.message));
      dispatch({
        type: 'RESET_PASSWORD_FAILURE',
        err: err.response.data.error.message || errorData
      });
    }
  };
}

export const getRefreshToken = () => async (dispatch, getState) => {
  const state = getState();
  const { token } = state.auth;
  const { refreshToken } = state.auth;

  try {
    const res = await axios.post(
      refreshUrl,
      {
        accessToken: token,
        device: generateDeviceInfo()
      },
      {
        headers: {
          authorization: refreshToken,
          'Content-Type': 'application/json'
        }
      }
    );
    // console.log('data', data)
    localStorage.removeItem('isFcmTokenSent');
    await dispatch(
      setToken(res.data.data.accessToken, res.data.data.refreshToken)
    );
    await dispatch(getAccountInfo());
    // eslint-disable-next-line no-console
    console.log('refreshed token');
    // await axios.post(pruningUrl, undefined, {
    //   headers: {
    //     authorization: token,
    //     'Content-Type': 'application/json',
    //   },
    // })

    return res.data;
  } catch (err) {
    const errorData = generateErrorDetail(err);
    dispatch(showToastNotif('error', errorData.message));
    throw new Error(errorData);
  }
};

export const pruneSession = () => async (dispatch, getState) => {
  const state = getState();
  const { token } = state.auth;
  try {
    token &&
      (await axios.post(pruningUrl, undefined, {
        headers: {
          authorization: token,
          'Content-Type': 'application/json'
        }
      }));
  } catch (err) {
    retryOperation(
      async () => {
        try {
          await axios.post(pruningUrl, undefined, {
            headers: {
              authorization: token,
              'Content-Type': 'application/json'
            }
          });
        } catch (error) {
          throw new Error(error);
        }
      },
      500,
      1
    );
  }
};

export const resetAuthState = () => (dispatch, getState) => {
  return dispatch({
    type: 'RESET_AUTH_STATE'
  });
};

const getFBToken = () => {
  return new Promise((resolve, reject) => {
    window.FB.login(
      (res) => {
        if (res.status !== 'connected') {
          reject(res);
        } else resolve(res.authResponse);
      },
      { scope: 'email,public_profile', auth_type: 'rerequest' }
    );
  });
};

export function signinWithGoogle(credentialResponse) {
  return async (dispatch, getState) => {
    const state = getState();
    const { consentSelection } = state.auth;
    try {
      dispatch({
        type: 'LOGIN'
      });
      // await window.gapi.auth2
      //   .getAuthInstance()
      //   .signIn({ scope: 'profile email', prompt: 'select_account' });

      // const googleUser = window.gapi.auth2.getAuthInstance().currentUser.get();
      // const ggToken = googleUser.getAuthResponse().id_token;

      const token = await axios.post(
        oauthURL,
        {
          code: credentialResponse.code
        },
        {
          headers: {
            'Cloudfront-JWT-AppId': JWT_APP_ID
          }
        }
      ); // get token from oauth credential response
      const ggToken = token.data.data.res.data.id_token;
      const res = await axios.post(
        sessionURLv12,
        {
          type: 'google',
          token: ggToken,
          device: generateDeviceInfo()
        },
        {
          headers: {
            'Cloudfront-JWT-AppId': JWT_APP_ID
          }
        }
      );

      const paramsEvent = {
        time: new Date().getTime(),
        userID: res.data.data.account.userId
      };
      const user = { ...res.data.data, telco: null };
      analytic(
        analyticTypes.event,
        analyticEvents.AUTH.COMPLETE_LOGIN_BY_GOOGLE,
        {
          params: paramsEvent,
          user
        }
      );
      await initFbPixelUserData(user);

      await dispatch(validateSession(user?.isNewDevice, user?.accessToken));
      dispatch({
        type: 'LOGIN_SUCCESS',
        data: res.data.data
      });

      if (consentSelection === null) {
        await dispatch(getConsent()); // dont call this if consentSelection is not null
      } else {
        await dispatch(postConsent());
      }
      // dispatch(getAccountInfo());
    } catch (err) {
      const errorData = generateErrorDetail(err);
      const { code } = errorData;
      let mes = '';

      if (
        code === errorCode.INVALID_CREDENTIAL ||
        code === errorCode.INVALID_PARAMETERS
      ) {
        mes = 'Invalid Credentials';
      } else if (err.error === 'popup_closed_by_user') {
        mes = 'You closed the login pop-up.';
      } else {
        mes = errorData.message;
      }

      dispatch({
        type: 'LOGIN_FAILURE',
        err: mes
      });
      throw err;
    }
  };
}

export function signinWithFB() {
  return async (dispatch, getState) => {
    const state = getState();
    const { consentSelection } = state.auth;
    try {
      dispatch({
        type: 'LOGIN'
      });
      const auth = await getFBToken();

      const res = await axios.post(
        sessionURLv12,
        {
          type: 'facebook',
          token: auth.accessToken,
          device: generateDeviceInfo({ fcmCheck: true })
        },
        {
          headers: {
            'Cloudfront-JWT-AppId': JWT_APP_ID
          }
        }
      );

      const paramsEvent = {
        time: new Date().getTime(),
        userID: res.data.data.account.userId
      };
      const user = {
        ...res.data.data,
        telco: null
      };
      analytic(
        analyticTypes.event,
        analyticEvents.AUTH.COMPLETE_LOGIN_BY_FACEBOOK,
        {
          params: paramsEvent,
          user
        }
      );
      clevertapUserLogin(res.data.data);
      await initFbPixelUserData(user);
      await dispatch(validateSession(user?.isNewDevice, user?.accessToken));
      dispatch({
        type: 'LOGIN_SUCCESS',
        data: res.data.data
      });
      if (consentSelection === null) {
        await dispatch(getConsent()); // dont call this if consentSelection is not null
      } else {
        await dispatch(postConsent());
      }
      // dispatch(getAccountInfo())
    } catch (err) {
      const code = err.response && err.response.data.error.code;
      let mes = '';

      if (
        code === errorCode.INVALID_CREDENTIAL ||
        code === errorCode.INVALID_PARAMETERS
      ) {
        mes = 'Invalid Credentials';
      } else {
        mes = err.response
          ? err.response.data.error.message
          : 'General Facebook Login Error';
      }

      dispatch({
        type: 'LOGIN_FAILURE',
        err: mes
      });
      throw err;
    }
  };
}

export const requestOtp = (phone) => {
  return axios.post(
    otpUrl,
    { mobile: phone },
    {
      headers: { 'Cloudfront-JWT-AppId': JWT_APP_ID }
    }
  );
};

export const verifyEmail = (token) => {
  return axios.post(
    verifyUrl,
    {
      token
    },
    {
      headers: { 'Cloudfront-JWT-AppId': JWT_APP_ID }
    }
  );
};

export function loginByPhone(phone, otp) {
  return async (dispatch, getState) => {
    const state = getState();
    const { consentSelection } = state.auth;
    try {
      dispatch({
        type: 'LOGIN'
      });

      const res = await axios.post(
        sessionURLv12,
        {
          type: 'mobile',
          username: phone,
          otp,
          device: generateDeviceInfo({ fcmCheck: true })
        },
        {
          headers: {
            'Cloudfront-JWT-AppId': JWT_APP_ID
          }
        }
      );

      const paramsEvent = {
        time: new Date().getTime(),
        userID: res.data.data.account.userId
      };
      const user = {
        ...res.data.data
      };
      analytic(
        analyticTypes.event,
        analyticEvents.AUTH.COMPLETE_LOGIN_BY_PHONE,
        {
          params: paramsEvent,
          user
        }
      );
      clevertapUserLogin(res.data.data);
      await initFbPixelUserData(user);
      await dispatch(validateSession(user?.isNewDevice, user?.accessToken));
      dispatch({
        type: 'LOGIN_SUCCESS',
        data: res.data.data
      });
      if (consentSelection === null) {
        await dispatch(getConsent()); // dont call this if consentSelection is not null
      } else {
        await dispatch(postConsent());
      }
      // dispatch(getAccountInfo())
    } catch (err) {
      const code = err.response ? err.response.data.error.code : err;
      let mes = '';

      if (
        code === errorCode.INVALID_CREDENTIAL ||
        code === errorCode.INVALID_PARAMETERS
      ) {
        mes = 'Invalid Credentials';
      } else {
        mes = err.response
          ? err.response.data.error.message
          : 'General Login Error';
      }

      dispatch({
        type: 'LOGIN_FAILURE',
        err: mes
      });
      throw err;
    }
  };
}

export const sendRefferalKey = (key) => async (dispatch, getSTate) => {
  const state = getSTate();
  const { token } = state.auth;

  try {
    await axios.post(
      campaignUrl,
      {
        type: 'showtime',
        key
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
  } catch (err) {
    if (err.toString().includes('Network Error')) {
      setTimeout(() => {
        dispatch(sendRefferalKey(key));
      }, 3000);
    }
  }
};

const mapErrors = (err) => {
  switch (err.code) {
    case 'gfx4000006':
      return 'The image format must be JPEG, PNG, or GIF and cannot exceed 10MB';
    default:
      return 'Something went wrong, please try again later';
  }
};

export function updateProfile(id, payload) {
  return async (dispatch, getState) => {
    const state = getState();
    const { token } = state.auth;

    dispatch({ type: 'UPDATE_PROFILE' });
    try {
      const res = await axios.put(`${profileUrl}/${id}`, payload, {
        headers: {
          Authorization: token
        }
      });

      dispatch({
        type: 'UPDATE_PROFILE_SUCCESS'
      });
      return res;
    } catch (err) {
      const errorData = generateErrorDetail(err);
      if (errorData) {
        dispatch({
          type: 'UPDATE_PROFILE_FAILURE',
          err: errorData.message
        });
      }
      dispatch(showToastNotif('error', mapErrors(errorData)));
    }
  };
}

export function updatePassword(currentPassword, password, confirmPassword) {
  return async (dispatch, getState) => {
    const state = getState();
    const { token } = state.auth;

    const payload = {
      password,
      confirmPassword,
      currentPassword
    };

    const res = await axios.post(`${updatePasswordUrl}`, payload, {
      headers: {
        Authorization: token
      }
    });

    return res.data;
  };
}

export function getWhatsappUrl() {
  return async (dispatch, getState) => {
    const state = getState();
    const { token, whatsappLink } = state.auth;

    dispatch({ type: 'GET_WHATSAPP' });
    try {
      if (!whatsappLink) {
        let res = await axios.get(whatsappLinkUrl, {
          headers: {
            Authorization: token,
            'Cloudfront-JWT-AppId': JWT_APP_ID
          }
        });

        if (Object.keys(res?.data).length === 0) {
          res = { data: { data: { value: undefined } } };
        }
        dispatch({ type: 'GET_WHATSAPP_SUCCESS', payload: res?.data });
        return res?.data;
      }

      dispatch({
        type: 'GET_WHATSAPP_SUCCESS_FROM_STATE',
        payload: whatsappLink
      });
    } catch (err) {
      const errorData = generateErrorDetail(err);
      dispatch({
        type: 'GET_WHATSAPP_FAILURE',
        err: errorData.message
      });
    }
  };
}

export function postConsent() {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      await axios.post(
        consentUrl,
        {
          accepted: state.auth.consentSelection
        },
        {
          headers: {
            Authorization: state.auth.token
          }
        }
      );
      dispatch({
        type: 'CLOSE_CONSENT_POPUP'
      });
    } catch (err) {
      const errorData = generateErrorDetail(err);
      throw new Error(errorData);
    }
  };
}

export function setProfileConsent(consent) {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      dispatch({
        type: 'GET_CONSENT_SUCCESS',
        payload: consent
      });
      await axios.post(
        consentUrl,
        {
          accepted: consent
        },
        {
          headers: {
            Authorization: state.auth.token
          }
        }
      );
    } catch (err) {
      const errorData = generateErrorDetail(err);
      dispatch(
        showToastNotif(
          'failed',
          'Some error occured, please refresh the page.' || errorData
        )
      );
    }
  };
}

export function getConsent() {
  return async (dispatch, getState) => {
    const state = getState();
    const { token } = state.auth;
    if (token) {
      try {
        const res = await axios.get(`${consentUrl}/latest`, {
          headers: {
            Authorization: token
          }
        });
        dispatch({
          type: 'GET_CONSENT_SUCCESS',
          payload: res.data.data.accepted
        });
      } catch (err) {
        if (err?.response?.status === 404) {
          dispatch({
            type: 'OPEN_CONSENT_POPUP'
          });
        } else {
          dispatch(
            showToastNotif(
              'failed',
              'Some error occured, please refresh the page.'
            )
          );
        }
      }
    }
  };
}

export function selectConsent(payload) {
  return (dispatch) => {
    dispatch({
      type: 'CONSENT_SELECT',
      payload
    });
  };
}

export const requestOtpChangeNumber = (phone) => {
  return axios.post(
    otpUrl,
    { mobile: phone, purpose: 'modifyPhoneNumber' },
    {
      headers: { 'Cloudfront-JWT-AppId': JWT_APP_ID }
    }
  );
};

export function updatePhoneNumber(payload) {
  return async (dispatch, getState) => {
    const state = getState();
    const { token } = state.auth;
    const { id } = state.auth.user.profile;

    // dispatch({ type: 'UPDATE_PROFILE' });
    try {
      await axios.post(`${profileUrl}/${id}/updatePhoneNumber`, payload, {
        headers: {
          Authorization: token
        }
      });

      // dispatch({
      //   type: 'UPDATE_PROFILE_SUCCESS',
      // });
    } catch (err) {
      const errorData = generateErrorDetail(err);
      // if (err.response && err.response.data) {
      //   dispatch({
      //     type: 'UPDATE_PROFILE_FAILURE',
      //     err: err.response.data.error.message,
      //   });
      // }
      throw new Error(errorData);
    }
  };
}
