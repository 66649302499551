/* eslint-disable prefer-const */
/* eslint-disable no-inner-declarations */
/* eslint-disable no-shadow */
import React, { useEffect, useRef, useState } from 'react';
import shaka from 'shaka-player';
import { useSelector } from 'react-redux';
import platform from 'platform';
import ShakaPlayer from './ShakaPlayer';
import PlayerSubscription from './PlayerSubscription';
import { withRouter } from '../../hooks';
import './ShakaVideoPlayer.css';
// import { errorCode } from '../../constants';
import { detectAgent, detectOS } from '../../utils';
import analytic, {
  analyticEvents,
  analyticTypes
} from '../../service/analytic';

import { getFairplayCert } from './palycon-helper';

function ShakaVideoPlayer({
  videoInfo,
  access,
  onDismissPlayer,
  handlePlayNext,
  handleUpdateHistory,
  setShouldGetHistory,
  historyData,
  autoplay = false,
  handleEndHistory,
  width = '100vw',
  height = '100vh',
  autoPlay,
  ...otherProps
}) {
  const controllerRef = useRef(null);
  // const videoPlayer = useRef()
  const [showClose, setShowClose] = useState(true);
  const [error, setError] = useState(false);
  const user = useSelector((state) => state.auth && state.auth.user);

  // useEffect(() => {
  //   return () => {
  //     videoPlayer.current && videoPlayer.current.destroy()
  //   };
  // }, []);

  // send heartbeat when user start media for the first time
  useEffect(() => {
    if (videoInfo) {
      const durationInSecond = videoInfo.duration * 60;
      handleUpdateHistory(0, durationInSecond);
    }
  }, [handleUpdateHistory, videoInfo]);

  useEffect(() => {
    // Observe the shaka player control to show close button at the left top
    const intervalDismissBtn = setInterval(() => {
      const el = document.querySelector('.shaka-controls-container');
      const elState = el ? el.getAttribute('shown') : true;
      setShowClose(elState);
    }, 500);

    return () => {
      clearInterval(intervalDismissBtn);
    };
  }, []);

  const { stream, drmType, drmToken, drmProvider } = access;
  useEffect(() => {
    if (
      access.error &&
      access.error.response &&
      access.error.response.data &&
      access.error.response.data.error
    ) {
      return setError(access.error.response.data.error);
    }

    try {
      const {
        /** @type {shaka.Player} */ player,
        /** @type {shaka.ui.Overlay} */ ui,
        /** @type {HTMLVideoElement} */ videoElement
        // /** @type {shaka.ui.Controls} */ controls,
      } = controllerRef.current;

      const uiConfig = {
        enableFullscreenOnRotation: true,
        forceLandscapeOnFullscreen: true,
        controlPanelElements: [
          'play_pause',
          'time_and_duration',
          ...(detectAgent('mobile') ? [] : ['rewind']),
          ...(detectAgent('mobile') ? [] : ['fast_forward']),
          'spacer',
          'mute',
          ...(detectAgent('mobile') ? [] : ['volume']),
          'fullscreen',
          'caption_format',
          'overflow_menu'
        ],
        overflowMenuButtons: [
          'captions',
          'quality',
          'language',
          'cast',
          'playback_rate'
        ]
      };
      ui.configure(uiConfig);

      async function initPlayer() {
        let contentUri;
        let playerConfig;
        contentUri = stream;

        // Listen for error events.
        player.addEventListener('error', onErrorEvent);

        if (drmType === 'FairPlay') {
          const fairplayCert = getFairplayCert(shaka);
          // console.log(fairplayCert)
          playerConfig = {
            drm: {
              servers: {
                'com.apple.fps':
                  drmProvider === 'aws'
                    ? 'https://license.pallycon.com/ri/licenseManager.do'
                    : drmToken
              },
              advanced: {
                'com.apple.fps':
                  drmProvider === 'aws'
                    ? {
                        serverCertificate: fairplayCert
                      }
                    : {
                        serverCertificateUri: '/fairplay.cer'
                      }
              }
            }
          };
        } else if (drmType === 'Widevine') {
          playerConfig = {
            drm: {
              servers: {
                'com.widevine.alpha':
                  drmProvider === 'aws'
                    ? 'https://license.pallycon.com/ri/licenseManager.do'
                    : drmToken
              },
              advanced: {
                'com.widevine.alpha': {
                  persistentStateRequired: true
                }
              }
            },
            streaming: {
              autoLowLatencyMode: true
            }
          };
        } else if (drmType === 'PlayReady') {
          playerConfig = {
            drm: {
              servers: {
                'com.microsoft.playready': {
                  serverURL:
                    drmProvider === 'aws'
                      ? 'https://license.pallycon.com/ri/licenseManager.do'
                      : drmToken,
                  systemStringPriority: [
                    'com.microsoft.playready.recommendation',
                    'com.microsoft.playready'
                  ]
                }
              }
            },
            streaming: {
              autoLowLatencyMode: true
            }
          };
        }

        // Try to load a manifest.
        // This is an asynchronous process.
        if (playerConfig) {
          player.configure(playerConfig);
        }

        player
          .load(contentUri)
          .then(async function () {
            const isByteplusDomain = getSubdomain(contentUri);
            if (isByteplusDomain && access?.subtitle) {
              // load sideload subtitle
              const subtitleUri = access?.subtitle;
              const subtitleLanguage = 'id';
              const subtitleKind = 'subtitles';
              const subtitleMimeType = 'text/srt';

              await player.addTextTrackAsync(
                subtitleUri,
                subtitleLanguage,
                subtitleKind,
                subtitleMimeType
              );

              // This runs if the asynchronous load is successful.
              const tracks = player.getTextTracks();
              if (tracks.length > 0) {
                player.selectTextTrack(tracks[0]);
                player.setTextTrackVisibility(true);
              }
            }

            // This runs if the asynchronous load is successful.
            // console.log('The video has now been loaded!');
            if (videoInfo) {
              // console.log('video Info', videoInfo)
              const paramsEvent = {
                contentType: videoInfo.type,
                videoID: videoInfo.id,
                videoTitle: videoInfo.titleLocalized,
                videoDuration: videoInfo.duration
              };
              if (videoInfo.tags && videoInfo.tags.genres) {
                paramsEvent.videoGenre = videoInfo.tags.genres;
              }
              if (videoInfo.season) {
                paramsEvent.seasonID = videoInfo.season.id;
                paramsEvent.seasonNumber = videoInfo.season.titleLocalized;
                paramsEvent.seriesID = videoInfo.series.id;
                paramsEvent.seriesName = videoInfo.series.titleLocalized;
              }

              analytic(
                analyticTypes.event,
                analyticEvents.MEDIA_PLAYBACK.MEDIA_LAUNCH,
                {
                  params: paramsEvent,
                  user
                }
              );
            }

            if (autoPlay && !navigator.userActivation.isActive) {
              window.autoplayAfterMuted = false;
              videoElement.muted = true;
              const interactions = ['click', 'keydown', 'touchstart'];

              const onInteract = () => {
                videoElement.muted = false;
                window.autoplayAfterMuted = true;

                interactions.forEach((item) => {
                  document.removeEventListener(item, onInteract);
                });
              };
              interactions.forEach((item) => {
                document.addEventListener(item, onInteract);
              });
            }
            videoElement.play();
          })
          .catch(onError); // onError is executed if the asynchronous load fails.

        // videoPlayer.current = player
      }

      function onErrorEvent(event) {
        // Extract the shaka.util.Error object from the event.
        // console.error('Error code', event.detail.code, 'object', event.detail);
        onError(event.detail);
      }

      function onError(error) {
        // Log the error.
        // console.error('Error code', error.code, 'object', error);

        if (error.code === 3016 && error.data[0]) {
          if (detectOS() === 'ios' && platform.name !== 'Safari') {
            setError({
              code: 'missing_video',
              message: 'Please open this content on safari browser.'
            });
          }
        }
      }

      initPlayer();
    } catch (error) {
      return setError({
        code: 'missing_video',
        message: 'Missing video asset to play.'
      });
    }

    return () => {
      setError(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [access, user, videoInfo]);

  if (error) {
    return (
      <PlayerSubscription
        onDismissPlayer={onDismissPlayer}
        otherProps={otherProps}
        error={error}
      />
    );
  }

  const unloadPlayer = () => {
    const { /** @type {shaka.Player} */ player } = controllerRef.current || {};
    player?.unload();
    setError(false);
  };

  const getSubdomain = (fullUrl) => {
    const url = new URL(fullUrl);

    const hostname = url.hostname;

    const parts = hostname.split('.');

    const subdomain = parts.length > 2 ? parts[0] : null;
    const isByteplus = subdomain === 'vod1' || subdomain === 'vod2';
    return isByteplus;
  };

  return (
    <>
      <div
        className='vjs-dismiss-btn-wrapper'
        onClick={() => {
          unloadPlayer();
          onDismissPlayer();
        }}
        style={{ visibility: showClose ? 'visible' : 'hidden' }}
      >
        <i className='vjs-dismiss-player-icon' />
      </div>
      <div>
        {drmProvider === 'aws' ? (
          <ShakaPlayer
            drmToken={access.drmToken}
            streamType={access.streamType}
            videoInfo={videoInfo}
            handlePlayNext={handlePlayNext}
            handleUpdateHistory={handleUpdateHistory}
            setShouldGetHistory={setShouldGetHistory}
            historyData={historyData}
            autoplay={autoplay}
            handleEndHistory={handleEndHistory}
            height={height}
            width={width}
            ref={controllerRef}
          />
        ) : (
          <ShakaPlayer
            streamType={access.streamType}
            videoInfo={videoInfo}
            handlePlayNext={handlePlayNext}
            handleUpdateHistory={handleUpdateHistory}
            setShouldGetHistory={setShouldGetHistory}
            historyData={historyData}
            autoplay={autoplay}
            handleEndHistory={handleEndHistory}
            height={height}
            width={width}
            ref={controllerRef}
          />
        )}
      </div>
    </>
  );
}

export default withRouter(React.memo(ShakaVideoPlayer));
